import { setRegisteredData } from "./forms/registered-data";

$('[name="personal_data[billType]"]').on('change', function() {
    $('[name="personal_data[billPos]"]', this.form.elements).closest('.form-group').prop('disabled', $(this).val() != 'Paragon');
}).trigger('change');

$('[name="personal_data[emailRepeat]"]').each(function() {
    let $email = $('[name="personal_data[email]"]', this.form.elements);
    let $emailRepeat = $('[name="personal_data[emailRepeat]"]', this.form.elements);
    $($email).add($emailRepeat).on('input', function() {
        $emailRepeat.toggleValid($email.val() == $emailRepeat.val());
    }).trigger('change');
});

$('#form-application').on('submit-success', function(event, data) {
    $('.first').addClass('d-none');
    $('.other').addClass('d-none');
    $('.page-form').addClass('d-none');
    $('.page-result').removeClass('d-none');
    $('.result').removeClass('d-none');

    if (data.type === 'first') {
        $('.s-hero h1').html('Brawo! Pierwszy <br/> kontakt nawiązany!');
    } else {
        $('.s-hero h1').html('Twoja misja powiodła <br/> się kolejny raz!');
    }

    $(`.${data.type === 'first' ? 'first' : 'other'}`).removeClass('d-none');

    $('html, body').animate({
        scrollTop: $(".s-thanks").offset().top - 120
    }, 100);
});

$('.game-run').on('click', function() {
    // page swap
    $('.page').addClass('d-none');
    $('.page-result').removeClass('d-none');
    $('.page-result').css('display', 'block');

    // modal (uncomment if you need modal instead of page swap)
    //$('#modal-form-application').modal('show');
});

$('.result-try-again').on('click', function() {
    $('.page').addClass('d-none');
    $('.page-form').removeClass('d-none');
});

$('[name="personal_data[noPesel]"]').on('change', function() {
    $('[name="personal_data[pesel]"]', this.form.elements).prop('disabled', $(this).prop('checked'));
    let fields = [
        '[name="personal_data[street]"]',
        '[name="personal_data[streetNr]"]',
        '[name="personal_data[flatNr]"]',
        '[name="personal_data[zipCode]"]',
        '[name="personal_data[city]"]',
    ];
    $(fields.join(', '), this.form.elements).closest('.form-group').prop('disabled', !$(this).prop('checked'));
}).trigger('change');

$('.winner-form').on('submit-success', function(event, data) {
    setRegisteredData(data, 'section#winner');

    // page swap
    $('.page').addClass('d-none');
    $('.page-thank-you').removeClass('d-none');
    $('.page-thank-you').css('display', 'block');

    // modal (uncomment if you need modal instead of page swap)
    //$('#modal-form-winner').modal('show');
});

$('.contact-form').on('submit-success', function(event, data) {
    setRegisteredData(data, 'section#contact');

    // page swap
    $('.page').addClass('d-none');
    $('.page-thank-you').removeClass('d-none');
    $('.page-thank-you').css('display', 'block');

    // modal (uncomment if you need modal instead of page swap)
    //$('#modal-form-contact').modal('show');
});